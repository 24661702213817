@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900&display=swap");

* {
  box-sizing: border-box;
}
body {
  background: white !important;
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: Lato !important;
}

::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 4px;
}

thead th {
  position: sticky;
  top: 0;
  background-color: #191b33 !important;
  z-index: 2;
}

.accept {
  color: green;
}

.reject {
  color: red;
}

h1,
h2,
h3,
h4,
h5,
h6,
label {
  font-weight: 500;
}

body,
html,
.App,
#root,
.page__container {
  width: 100%;
  height: 100%;
}

.page-main__container {
  display: flex;
  justify-content: flex-end;
  text-align: left;
  height: 100vh;
}

.page__container .form-control:focus {
  border-color: #00aaff;
  box-shadow: none;
}

.min_width {
  min-width: 100px;
}

@import "components/login.css";
@import "components/extractor.css";
@import "components/header.css";
@import "components/viewer.css";
@import "components/messageCard.css";
@import "components/table.css";

.header__container {
  width: 100%;
  height: 110px;
  background-color: #191b33;
  color: white;
  display: flex;
  padding: 0px 37px 0px 37px;
  align-items: center;
  justify-content: space-between;
}

.profile__container {
  transition: all 0.5s ease;
  width: 240px;
  display: flex;
  font-size: 28px;
  align-items: center;
  justify-content: space-between;
}

.letter__container {
  margin-left: 10px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 70px;
  height: 70px;
  border-radius: 34px;
  border: 1px solid #00aaff;
}

.letter__main {
  transform: scale(0.6);
}

.button-header {
  position: relative;
  background: transparent;
  border: none;
  outline: none;
}

.button-header:focus {
  outline: none;
}

.button-header:hover {
  color: #00aaff;
}

.dropdown-header__container {
  position: absolute;
  background-color: #191b33;
  width: 235px;
  top: 10%;
  border-radius: 0px 0px 16px 16px;
  z-index: 100;
}

.dropdown-header__option {
  height: 60px;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.dropdown-header__option:last-child {
  border-radius: 0px 0px 16px 16px;
}

.dropdown-header__option:hover {
  background-color: #00aaff;
  transition: 0.5s;
  cursor: pointer;
  color: #191b33;
}

.logo-elint-solo {
  transform: scale(0.6);
  margin-left: -65px;
}

.elint__lens-logo {
  display: flex;
}

.lens__header {
  margin-top: 22px;
  margin-left: -64px;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  font-weight: 700;
  font-size: 50px;
  font-family: "Staatliches";
  color: #2e9dc6;
}

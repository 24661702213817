.main__container {
  display: flex;
  flex-direction: column;
  color: white !important;
  width: 550px;
  background-color: #191b33;
  padding: 0px 5em;
  justify-content: center;
}

span {
  font-weight: 700;
  font-size: 50px;
}

h3 {
  font-weight: 500;
  font-size: 22px;
}
.welcome__container {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

form {
  margin-top: 60px;
}

.form-control {
  margin-top: 20px;
  height: 56px;
}

.buttons__container {
  display: flex;
  justify-content: space-between;
  height: 50px;
  padding: 0;
  width: 332px;
  margin-top: 20px;
}

a {
  text-decoration: none !important;
  color: white;
}

.color-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00aaff;
  width: 158px;
  height: 50px;
  color: white;
}
.color-button:focus {
  outline: none !important;
}

.btn-block {
  margin-top: 0px !important;
}

.logo__container {
  font-weight: 900;
  font-size: 90px;
  color: #00aaff;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-self: center;
  vertical-align: middle;
}

.logo__main {
  font-weight: 900;
  font-size: 90px;
  color: #00aaff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-self: center;
  vertical-align: middle;
}

.lens {
  font-weight: 900;
  margin-top: 10px;
  font-family: "Staatliches";
  color: #2e9dc6;
}

.back-icon {
  background-color: none;
  display: flex;
  justify-content: left;
  align-items: center;
  width: 80px;
  height: 50px;
  color: white;
}

.error {
  display: flex;
  justify-content: center;
  border: 1px solid red;
  border-radius: 4px;
  font-size: 10px;
  color: red;
  margin-top: 10px;
  margin-bottom: -10px;
  background-color: #f79696;
  font-weight: 700;
  padding: 2px;
}
.success {
  display: flex;
  justify-content: center;
  border: 1px solid green;
  border-radius: 4px;
  font-size: 10px;
  color: green;
  margin-top: 10px;
  margin-bottom: -10px;
  background-color: #8be78b;
  font-weight: 700;
  padding: 2px;
}

.forgot-password {
  font-size: 12px;
  margin-left: 235px;
}

.forgot-password:hover {
  color: #00aaff;
}

.engie {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: -30px;
}
.engie > svg {
  transform: scale(1.5);
}

.viewer__wrapper {
  width: 1350px;
  font-size: 18px;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: space-between;
  margin-top: 60px;
  margin-bottom: 38px;
}

.viewer__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
  align-content: center;
  margin-top: -45px;
}

.viewer__main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #191b33;
  width: 800px;
  height: 700px;
  border: 10px solid #191b33;
  border-radius: 10px;
  font-size: 22px;
  font-weight: 900;
  color: white;
  margin-bottom: 10px;
}

.list__container {
  width: 450px;
  height: 760px;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  margin-top: 1px;
}

.list__main {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #191b33;
  width: 550px;
  height: 700px;
  border: 10px solid #191b33;
  border-radius: 10px;
  font-size: 22px;
  font-weight: 700;
  color: white;
  margin-bottom: 10px;
}

.editable__container input {
  text-align: center;
}

.editable__container {
  display: flex;
  color: white;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: -2px;
}

.editable__main {
  text-align: right !important;
  display: flex;
  color: white !important;
  font-size: 18px !important;
  font-weight: 700 !important;
}

.editable__icon {
  color: white;
  margin-left: 7px;
  margin-top: -3px;
}

.viewer-buttons {
  display: flex;
  align-self: center;
  align-items: center;
  color: #00aaff;
  cursor: pointer;
}

.position__container {
  position: relative;
}
.position__main {
  position: absolute;
  height: 20px;
  border: 1px solid red;
  z-index: 10000;
}

.pdf_title {
  font-size: 25px;
  margin-bottom: 10px;
}

.pdf_title:focus {
  outline: none !important;
}

.css-2b097c-container {
  width: 150px;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.css-yk16xz-control {
  background-color: #191b33 !important;
}
.css-1pahdxg-control {
  background-color: #191b33 !important;
}

.css-1uccc91-singleValue {
  color: white !important;
}

.css-b8ldur-Input {
  color: white !important;
}

.back__button-container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  font-size: 24px;
  margin-top: 15px;
  margin-bottom: -25px;
  margin-left: 90px;
}

.back__button {
  cursor: pointer;
  background-color: #00aaff;
  color: white;
  border: 1px solid #00aaff;
  width: 70px;
  display: flex;
  justify-content: center;
  border-radius: 6px;
}

.feedback__button {
  font-size: 14px !important;
  background: #191b33;
  color: white;
  border: 1px solid #191b33 !important;
  border-radius: 4px;
  font-weight: 600;
  margin-left: 10px;
}

.feedback__button:hover {
  transition: 0.5s;
  background: none;
  color: #191b33;
}

.feedback__title {
  font-size: 20px;
  font-weight: 600;
  margin-top: 10px;
  margin-left: 22px;
  color: #191b33;
}

.feedback__text {
  font-size: 14px !important;
  margin-bottom: 10px;
  color: #191b33;
}

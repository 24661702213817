.table__container {
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
}

.table-extractor__container {
  height: 600px;
  width: 650px;
  overflow-y: auto;
  overflow-x: hidden;
}

th:not(:last-child):after {
  width: 200px;
}

.th-extractor:last-child {
  width: 130px;
}

.table__container td {
  border-bottom: 1px solid #cfcfcf !important;
  align-content: center !important;
  vertical-align: middle !important;
}
.table__container th {
  border-bottom: 1px solid #cfcfcf !important;
}

.table__head {
  min-width: 650px;
  border-bottom: 1px solid #cfcfcf;
  color: white;
  font-size: 18px;
}

.table__header {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  background-color: #191b33 !important;
  z-index: -1;
}

.table__header-icon {
  display: flex;
  justify-content: center;
  width: 25px;
  margin-top: 2px;
  margin-right: -20px;
  vertical-align: middle;
}

.input-sm {
  margin-top: -3px;
  height: 30px;
  width: 90px;
}

.table__head th div {
  display: flex;
  vertical-align: middle;
  justify-content: center;
}
.table__body span {
  display: flex;
  justify-content: center;
  font-size: 18px !important;
  color: white;
  vertical-align: middle !important;
}

.table__icon {
  margin-left: -10px;
  display: flex;
  cursor: pointer;
  justify-content: space-around;
  color: #cfcfcf !important;
}

.table__icons {
  display: flex;
  align-items: center;
  width: 35px;
  justify-content: space-between;
}

.table__filter {
  color: black;
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

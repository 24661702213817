.page-extractor__container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  vertical-align: middle;
}

.body__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.data__container {
  display: flex;
  justify-content: space-between;
  width: 1140px;
  height: 600px;
  margin-right: 80px;
  vertical-align: middle;
  align-items: center;
}
.extract__container {
  width: 450px;
  height: 575px;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  vertical-align: middle;
  padding-top: 230px;
}

.extract__main {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  background-color: #191b33;
  width: 450px;
  height: 513px;
  border: 10px solid #191b33;
  border-radius: 10px;
  font-size: 22px;
  font-weight: 900;
  color: white;
}

.download__container {
  width: 450px;
  height: 385px;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
}

.download__main {
  display: flex;
  flex-direction: column;
  background-color: #191b33;
  width: 295px;
  height: 313px;
  border: 10px solid #191b33;
  border-radius: 10px;
  font-size: 22px;
  font-weight: 900;
  color: white;
  margin-bottom: 10px;
  overflow-x: hidden;
  overflow-y: auto;
}

.download__option {
  display: flex;
  color: white;
  justify-content: space-around;
  vertical-align: middle !important;
  align-items: center !important;
  align-self: center !important;
  align-content: center !important;
  font-size: 20px;
}

.download__icons {
  width: 120px;
  font-size: 20px;
  margin-top: -4px;
  display: flex;
  justify-content: space-around;
  align-self: center !important;
  align-content: center !important;
  vertical-align: middle !important;
  align-items: center !important;
}

.dropzone {
  width: 100%;
  height: 100%;
  border: 1px dashed white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  cursor: pointer;
  padding-top: 120px;
}

.footer_container {
  vertical-align: middle;
  align-items: center;
  display: flex;
  width: 295px;
  justify-content: center;
  padding-left: 30px;
  padding-bottom: 30px;
}

.refresh_icon {
  vertical-align: middle;
  background-color: transparent;
  border: none;
  width: 35px;
  height: 35px;
}
.refresh_icon.active.focus,
.refresh_icon.active:focus,
.refresh_icon.focus,
.refresh_icon:active.focus,
.refresh_icon:active:focus,
.refresh_icon:focus {
  border-style: outset;
  border: none;
  outline: none;
}

.how-it-works {
  display: flex;
  justify-content: left;
  color: #191b33;
  font-size: 45px;
  font-weight: 700;
  width: 100%;
  margin-top: 25px;
  margin-bottom: -85px;
  padding-left: 35px;
}

.extract__title {
  font-size: 24px;
  margin-top: -8px;
  margin-bottom: 8px;
}

.extract__table-title {
  font-size: 24px;
  width: 300px;
  margin-right: -10px;
}

.extract__table-upper {
  display: flex;
  justify-content: flex-start;
  margin-left: 230px;
  width: 450px;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.message__container {
  font-size: 12px;
  padding: 20px;
  background-color: #191b33;
  border-radius: 6px;
  width: 450px;
  /* height: 315px; */
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
}

.message_title {
  margin-bottom: -37px;
  margin-top: -37px;
}

.message__header {
  display: flex;
  font-size: 16px;
  font-weight: 700;
}
.message-header__button {
  background: none;
  border: none;
  color: white;
  font-size: 18px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
  margin-left: 380px;
}

.message-header__button:hover {
  color: red;
}
.message-header__button:focus {
  outline: none;
}

.message_info {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 36px;
  color: white;
  justify-content: center;
}

.button-update {
  margin-left: 250px;
}

@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700,900&display=swap);
.main__container {
  display: flex;
  flex-direction: column;
  color: white !important;
  width: 550px;
  background-color: #191b33;
  padding: 0px 5em;
  justify-content: center;
}

span {
  font-weight: 700;
  font-size: 50px;
}

h3 {
  font-weight: 500;
  font-size: 22px;
}
.welcome__container {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

form {
  margin-top: 60px;
}

.form-control {
  margin-top: 20px;
  height: 56px;
}

.buttons__container {
  display: flex;
  justify-content: space-between;
  height: 50px;
  padding: 0;
  width: 332px;
  margin-top: 20px;
}

a {
  text-decoration: none !important;
  color: white;
}

.color-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00aaff;
  width: 158px;
  height: 50px;
  color: white;
}
.color-button:focus {
  outline: none !important;
}

.btn-block {
  margin-top: 0px !important;
}

.logo__container {
  font-weight: 900;
  font-size: 90px;
  color: #00aaff;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-self: center;
  vertical-align: middle;
}

.logo__main {
  font-weight: 900;
  font-size: 90px;
  color: #00aaff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-self: center;
  vertical-align: middle;
}

.lens {
  font-weight: 900;
  margin-top: 10px;
  font-family: "Staatliches";
  color: #2e9dc6;
}

.back-icon {
  background-color: none;
  display: flex;
  justify-content: left;
  align-items: center;
  width: 80px;
  height: 50px;
  color: white;
}

.error {
  display: flex;
  justify-content: center;
  border: 1px solid red;
  border-radius: 4px;
  font-size: 10px;
  color: red;
  margin-top: 10px;
  margin-bottom: -10px;
  background-color: #f79696;
  font-weight: 700;
  padding: 2px;
}
.success {
  display: flex;
  justify-content: center;
  border: 1px solid green;
  border-radius: 4px;
  font-size: 10px;
  color: green;
  margin-top: 10px;
  margin-bottom: -10px;
  background-color: #8be78b;
  font-weight: 700;
  padding: 2px;
}

.forgot-password {
  font-size: 12px;
  margin-left: 235px;
}

.forgot-password:hover {
  color: #00aaff;
}

.engie {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: -30px;
}
.engie > svg {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}

.page-extractor__container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  vertical-align: middle;
}

.body__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.data__container {
  display: flex;
  justify-content: space-between;
  width: 1140px;
  height: 600px;
  margin-right: 80px;
  vertical-align: middle;
  align-items: center;
}
.extract__container {
  width: 450px;
  height: 575px;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  vertical-align: middle;
  padding-top: 230px;
}

.extract__main {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  background-color: #191b33;
  width: 450px;
  height: 513px;
  border: 10px solid #191b33;
  border-radius: 10px;
  font-size: 22px;
  font-weight: 900;
  color: white;
}

.download__container {
  width: 450px;
  height: 385px;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
}

.download__main {
  display: flex;
  flex-direction: column;
  background-color: #191b33;
  width: 295px;
  height: 313px;
  border: 10px solid #191b33;
  border-radius: 10px;
  font-size: 22px;
  font-weight: 900;
  color: white;
  margin-bottom: 10px;
  overflow-x: hidden;
  overflow-y: auto;
}

.download__option {
  display: flex;
  color: white;
  justify-content: space-around;
  vertical-align: middle !important;
  align-items: center !important;
  align-self: center !important;
  align-content: center !important;
  font-size: 20px;
}

.download__icons {
  width: 120px;
  font-size: 20px;
  margin-top: -4px;
  display: flex;
  justify-content: space-around;
  align-self: center !important;
  align-content: center !important;
  vertical-align: middle !important;
  align-items: center !important;
}

.dropzone {
  width: 100%;
  height: 100%;
  border: 1px dashed white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  cursor: pointer;
  padding-top: 120px;
}

.footer_container {
  vertical-align: middle;
  align-items: center;
  display: flex;
  width: 295px;
  justify-content: center;
  padding-left: 30px;
  padding-bottom: 30px;
}

.refresh_icon {
  vertical-align: middle;
  background-color: transparent;
  border: none;
  width: 35px;
  height: 35px;
}
.refresh_icon.active.focus,
.refresh_icon.active:focus,
.refresh_icon.focus,
.refresh_icon:active.focus,
.refresh_icon:active:focus,
.refresh_icon:focus {
  border-style: outset;
  border: none;
  outline: none;
}

.how-it-works {
  display: flex;
  justify-content: left;
  color: #191b33;
  font-size: 45px;
  font-weight: 700;
  width: 100%;
  margin-top: 25px;
  margin-bottom: -85px;
  padding-left: 35px;
}

.extract__title {
  font-size: 24px;
  margin-top: -8px;
  margin-bottom: 8px;
}

.extract__table-title {
  font-size: 24px;
  width: 300px;
  margin-right: -10px;
}

.extract__table-upper {
  display: flex;
  justify-content: flex-start;
  margin-left: 230px;
  width: 450px;
}

.header__container {
  width: 100%;
  height: 110px;
  background-color: #191b33;
  color: white;
  display: flex;
  padding: 0px 37px 0px 37px;
  align-items: center;
  justify-content: space-between;
}

.profile__container {
  transition: all 0.5s ease;
  width: 240px;
  display: flex;
  font-size: 28px;
  align-items: center;
  justify-content: space-between;
}

.letter__container {
  margin-left: 10px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 70px;
  height: 70px;
  border-radius: 34px;
  border: 1px solid #00aaff;
}

.letter__main {
  -webkit-transform: scale(0.6);
          transform: scale(0.6);
}

.button-header {
  position: relative;
  background: transparent;
  border: none;
  outline: none;
}

.button-header:focus {
  outline: none;
}

.button-header:hover {
  color: #00aaff;
}

.dropdown-header__container {
  position: absolute;
  background-color: #191b33;
  width: 235px;
  top: 10%;
  border-radius: 0px 0px 16px 16px;
  z-index: 100;
}

.dropdown-header__option {
  height: 60px;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.dropdown-header__option:last-child {
  border-radius: 0px 0px 16px 16px;
}

.dropdown-header__option:hover {
  background-color: #00aaff;
  transition: 0.5s;
  cursor: pointer;
  color: #191b33;
}

.logo-elint-solo {
  -webkit-transform: scale(0.6);
          transform: scale(0.6);
  margin-left: -65px;
}

.elint__lens-logo {
  display: flex;
}

.lens__header {
  margin-top: 22px;
  margin-left: -64px;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  font-weight: 700;
  font-size: 50px;
  font-family: "Staatliches";
  color: #2e9dc6;
}

.viewer__wrapper {
  width: 1350px;
  font-size: 18px;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: space-between;
  margin-top: 60px;
  margin-bottom: 38px;
}

.viewer__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
  align-content: center;
  margin-top: -45px;
}

.viewer__main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #191b33;
  width: 800px;
  height: 700px;
  border: 10px solid #191b33;
  border-radius: 10px;
  font-size: 22px;
  font-weight: 900;
  color: white;
  margin-bottom: 10px;
}

.list__container {
  width: 450px;
  height: 760px;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  margin-top: 1px;
}

.list__main {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #191b33;
  width: 550px;
  height: 700px;
  border: 10px solid #191b33;
  border-radius: 10px;
  font-size: 22px;
  font-weight: 700;
  color: white;
  margin-bottom: 10px;
}

.editable__container input {
  text-align: center;
}

.editable__container {
  display: flex;
  color: white;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: -2px;
}

.editable__main {
  text-align: right !important;
  display: flex;
  color: white !important;
  font-size: 18px !important;
  font-weight: 700 !important;
}

.editable__icon {
  color: white;
  margin-left: 7px;
  margin-top: -3px;
}

.viewer-buttons {
  display: flex;
  align-self: center;
  align-items: center;
  color: #00aaff;
  cursor: pointer;
}

.position__container {
  position: relative;
}
.position__main {
  position: absolute;
  height: 20px;
  border: 1px solid red;
  z-index: 10000;
}

.pdf_title {
  font-size: 25px;
  margin-bottom: 10px;
}

.pdf_title:focus {
  outline: none !important;
}

.css-2b097c-container {
  width: 150px;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.css-yk16xz-control {
  background-color: #191b33 !important;
}
.css-1pahdxg-control {
  background-color: #191b33 !important;
}

.css-1uccc91-singleValue {
  color: white !important;
}

.css-b8ldur-Input {
  color: white !important;
}

.back__button-container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  font-size: 24px;
  margin-top: 15px;
  margin-bottom: -25px;
  margin-left: 90px;
}

.back__button {
  cursor: pointer;
  background-color: #00aaff;
  color: white;
  border: 1px solid #00aaff;
  width: 70px;
  display: flex;
  justify-content: center;
  border-radius: 6px;
}

.feedback__button {
  font-size: 14px !important;
  background: #191b33;
  color: white;
  border: 1px solid #191b33 !important;
  border-radius: 4px;
  font-weight: 600;
  margin-left: 10px;
}

.feedback__button:hover {
  transition: 0.5s;
  background: none;
  color: #191b33;
}

.feedback__title {
  font-size: 20px;
  font-weight: 600;
  margin-top: 10px;
  margin-left: 22px;
  color: #191b33;
}

.feedback__text {
  font-size: 14px !important;
  margin-bottom: 10px;
  color: #191b33;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.message__container {
  font-size: 12px;
  padding: 20px;
  background-color: #191b33;
  border-radius: 6px;
  width: 450px;
  /* height: 315px; */
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
}

.message_title {
  margin-bottom: -37px;
  margin-top: -37px;
}

.message__header {
  display: flex;
  font-size: 16px;
  font-weight: 700;
}
.message-header__button {
  background: none;
  border: none;
  color: white;
  font-size: 18px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
  margin-left: 380px;
}

.message-header__button:hover {
  color: red;
}
.message-header__button:focus {
  outline: none;
}

.message_info {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 36px;
  color: white;
  justify-content: center;
}

.button-update {
  margin-left: 250px;
}

.table__container {
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
}

.table-extractor__container {
  height: 600px;
  width: 650px;
  overflow-y: auto;
  overflow-x: hidden;
}

th:not(:last-child):after {
  width: 200px;
}

.th-extractor:last-child {
  width: 130px;
}

.table__container td {
  border-bottom: 1px solid #cfcfcf !important;
  align-content: center !important;
  vertical-align: middle !important;
}
.table__container th {
  border-bottom: 1px solid #cfcfcf !important;
}

.table__head {
  min-width: 650px;
  border-bottom: 1px solid #cfcfcf;
  color: white;
  font-size: 18px;
}

.table__header {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  background-color: #191b33 !important;
  z-index: -1;
}

.table__header-icon {
  display: flex;
  justify-content: center;
  width: 25px;
  margin-top: 2px;
  margin-right: -20px;
  vertical-align: middle;
}

.input-sm {
  margin-top: -3px;
  height: 30px;
  width: 90px;
}

.table__head th div {
  display: flex;
  vertical-align: middle;
  justify-content: center;
}
.table__body span {
  display: flex;
  justify-content: center;
  font-size: 18px !important;
  color: white;
  vertical-align: middle !important;
}

.table__icon {
  margin-left: -10px;
  display: flex;
  cursor: pointer;
  justify-content: space-around;
  color: #cfcfcf !important;
}

.table__icons {
  display: flex;
  align-items: center;
  width: 35px;
  justify-content: space-between;
}

.table__filter {
  color: black;
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

* {
  box-sizing: border-box;
}
body {
  background: white !important;
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: Lato !important;
}

::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 4px;
}

thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #191b33 !important;
  z-index: 2;
}

.accept {
  color: green;
}

.reject {
  color: red;
}

h1,
h2,
h3,
h4,
h5,
h6,
label {
  font-weight: 500;
}

body,
html,
.App,
#root,
.page__container {
  width: 100%;
  height: 100%;
}

.page-main__container {
  display: flex;
  justify-content: flex-end;
  text-align: left;
  height: 100vh;
}

.page__container .form-control:focus {
  border-color: #00aaff;
  box-shadow: none;
}

.min_width {
  min-width: 100px;
}

